.add-rule-modal {
  &__select {
    width: 210px;
  }

  &__tags {
    margin-top: 19px;
    padding: 18px 0;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize-xxs;
    line-height: 22px;
  }

  &__search {
    width: 264px;
  }

  &__common-search {
    margin-top: 6px;
  }

  &__common-tags {
    min-height: 60px;
  }

  &__bundles-tags {
    min-height: 32px;
  }
}
