.general {
  &__container {
    display: flex;
    padding: 38px 0;
  }
  &__form {
    flex: 1;

    &__add_tag {
      position: absolute !important;
      text-transform: uppercase;
    }
  }

  &__translations {
    margin-right: 32px;
  }
}
