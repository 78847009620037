.login-page {
  background-image: url(../../images/bg.png);
  background-color: $color-grayLighten;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;

  &_logo {
    &::after {
      position: absolute;
      content: '';
      background-image: url(../../images/svg/logo.svg);
      background-repeat: no-repeat;
      background-size: 100% 100%;

      width: 72px;
      height: 95px;

      margin-left: 20px;
      margin-top: -23px;
    }

    font-weight: 600;
    font-size: 30px;
    color: $color-dark;
    vertical-align: middle;
    text-align: center;

    margin-top: 110px;
    margin-bottom: 86px;
  }

  &_main-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    form {
      margin-top: 22px;
      width: 360px;

      button {
        width: 100%;
        height: 40px;

        font-size: 16px;
        line-height: 24px;

        .ant-spin {
          height: 20px;
          top: 9px;
        }
      }

      .ant-input {
        text-align: center;
        font-size: 16px;

        &-prefix {
          svg {
            width: 16px;
            height: 16px;

            fill: $color-primary;
          }
        }
      }
    }
  }

  .ant-tabs {
    &-nav {
      width: 360px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.03);

      &-wrap {
        justify-content: center;
      }
    }

    &-tab-btn {
      font-size: 16px;
      letter-spacing: 0.5px;
      color: black !important;
    }

    &-ink-bar {
      background: black;
    }
  }
}
