@import '../abstracts/variables';

.loading_spinner {
  z-index: 20000 !important;
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ant-spin-nested-loading {
  width: 100%;
}
