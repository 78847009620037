.list {
  &__search-wrapper {
    display: flex;
    column-gap: 16px;
    margin-bottom: 42px;
  }

  &__search-name,
  &__search-tags {
    max-width: 100%;
  }

  &__search-tags {
    width: 100%;
  }

  &__table {
    &_actions-column {
      width: 65px;
    }

    &_questions-action {
      margin: 0 10px;
    }
  }

  &__pagination {
    margin: 16px;
  }
}
