.bundle-visibility {
  padding: 30px;

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: $fontSize-xxs;
    line-height: 22px;
  }

  &__btn {
    margin-top: 3px;
  }

  &__table {
    margin-top: 34px;
  }
}
