@import '../abstracts/variables';

.basic {
  &__header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: $header-height;
    &_logo {
      height: $header-logo-height;
    }
  }
  &__layout {
    margin-top: $header-height;
    width: 100%;
  }
}
