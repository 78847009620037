// Color variables
$color-white: #ffffff;
$color-whiteDark: #f4f4f4;
$color-whiteDarkest: #ededed;
$color-grayLightest: #d1d1d1;
$color-grayLight: #adadad;
$color-grayLighten: #f0f2f5;
$color-gray: #999999;
$color-grayDark: #666666;
$color-blackLight: #333333;
$color-black: #1f1f1f;

$color-orange: #ff4938;
$color-orangeDark: #f03e2d;
$color-green: #83d14f;
$color-blue: #0289d6;
$color-primary: #1890ff;
$color-red: #f5222d;

$color-layout: #fbfbfb;
$color-shadow: #00000026;
$color-dark: rgba(0, 0, 0, 0.85);
// Screen size variables
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md-break: 767px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

// Spaces
$spacer: 8px;

$spacer-0: calc($spacer * 0);
$spacer-1: calc($spacer / 4);
$spacer-2: calc($spacer / 2);
$spacer-3: calc($spacer);
$spacer-4: calc($spacer * 1.5);
$spacer-5: calc($spacer * 2);
$spacer-6: calc($spacer * 2.5);
$spacer-7: calc($spacer * 3);
$spacer-8: calc($spacer * 3.5);
$spacer-9: calc($spacer * 4);
$spacer-10: calc($spacer * 4.5);
$spacer-11: calc($spacer * 5);

// Font family variables
$fontFamily-default: 'Roboto';
$fontFamily-light: 'Roboto Light';
$fontFamily-bold: 'Roboto Bold';

// Font size variables
$fontSize-xxxs: 12px;
$fontSize-xxs: 14px;
$fontSize-xs: 16px;
$fontSize-sm: 18px;
$fontSize-md: 20px;
$fontSize-lg: 24px;
$fontSize-xlg: 26px;
$fontSize-xxlg: 36px;

// Header
$header-height: 60px;
$header-logo-height: 30px;

// Font weight
$fontWeight-xxl: 900;
$fontWeight-lg: 500;

// Modal window
$modalWidth: 350px;

// Border radius
$border-radius: 6px;
$border-radius-content: 8px;

// Antd button height
$button-large: 40px;

// Header
$logo-img-sm: 48px;
$logo-img-xs: 32px;
