.root-public-page {
  &__layout {
    min-height: 100vh;
    box-sizing: border-box;
  }

  &__logo {
    margin-top: 9px !important;
    margin-bottom: 15px !important;
  }

  &__logo-icon {
    width: 36px;
    height: 36px;
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
    background-image: url(../../images/svg/logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &__logo-title {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: $fontSize-sm;
    line-height: 32px;
    color: $color-primary;
    vertical-align: middle;
  }

  &__main {
    width: 100%;
    min-width: 0;
    height: inherit;
    display: flex;
    flex-direction: column;
  }

  &__content {
    padding: 21px 31px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__page {
    display: flex;
    flex-direction: column;
    background: $color-white;
  }
}
