.entries {
  position: relative;

  &_table {
    overflow-x: auto;

    .ant-table-cell {
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &_pagination {
    display: flex;
    justify-content: flex-end;

    padding: 15px 0;
    padding-right: 15px;
  }
}
