.translations {
  &_container {
    padding: 40px 26px;
    display: flex;
  }

  &-title {
    font-size: $fontSize-xs;
    line-height: 24px;
    margin-bottom: $spacer-0;
  }

  &-content {
    &_container {
      max-width: 350px;
      width: 100%;
    }
    &__list {
      margin-top: 20px;
      margin-bottom: 23px;
      border-radius: 8px;
    }
    &-select {
      margin-bottom: 23px;
    }
  }
}
